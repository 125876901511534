<script setup>
    import SaveMcLogo from "@/components/navigation/LogoIcon";
    import MenuComponent from "@/components/navigation/menu/MenuList";
    import ThemeSwitch from "@/components/theme/ThemeSwitch";

    let nav = document.getElementById('#logo');

    let stickyNav = async function() {
        let scrollY = window.scrollTop;
        // console.log(scrollY)

        if (nav != null) {
            let classList = nav.classList;

            if (scrollY > 200) {
                if (!classList.contains('logo-after')) {
                    classList.add('logo-after')
                }
            }
            else if (classList.contains('logo-after')) {
                classList.remove('logo-after')
            }
        }

        setTimeout(() => { stickyNav() }, 10)
    };

    stickyNav()
</script>


<template>
    <nav>
        <SaveMcLogo/>
        <MenuComponent/>
        <ThemeSwitch/>
    </nav>
</template>


<script>
    export default {
        name: "MainNavigation",
    }
</script>


<style lang="sass">
    .logo-after
        width: 200%

    @media only screen and (max-width: 980px)
        .menu
            display: none !important
        .mobile-menu
            display: block !important
            justify-self: end
            order: 3
            margin-right: 10px
        #logo
            width: calc(100% - 2.6rem - 2.6rem - 5rem)

    @media only screen and (max-width: 660px)
        #logo h1
            font-size: 1.7em
</style>

<style lang="sass" scoped>
    nav
        position: fixed
        width: 100%
        height: 100px

        display: flex
        align-items: center
        justify-content: space-around

        backdrop-filter: blur(6px) grayscale(0%)
        background: rgb(0, 0, 0, 0.15)
        transition: background 0.2s ease-in-out

</style>

