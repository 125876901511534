<template>
    <div class="contact">
        <h3>Kontakt</h3>
        <ul>
          <li>WhatsApp +48 720 891 444 *</li>
          <li><b style="font-size: 0.7em">*Kontakt i rezerwacja, nie jest przedstawicielem firmy SiTaxBahima, ani przewodnikiem. Za realizację wycieczki odpowiada:</b></li>
          <li style="font-size: 0.85em">Firma <b>SiTaxBahima</b> jest organizatorem wycieczek.</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ContactIn"
    }
</script>

<style scoped>

</style>