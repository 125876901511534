<template>
  <div class="col-12 col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="2000">
    <div class="offer-tile $bestseller">
      <img :src="'/offers/' + this.item.image" :alt="item.name">

      <p data-aos="fade-right" data-aos-duration="500" class="info-location">{{ item.location }}</p>
      <h4 data-aos="fade-right" data-aos-duration="500">{{ item.name }}</h4>

      <OfferBadge :location-type="item.locationType"/>
      <p class="info-description" data-aos="fade-right" data-aos-duration="500">
        <span v-html="item.shortDescription"></span>
        <a :href="'/offer/' + item.name.replace(' ', '-')">Czytaj dalej...</a>
      </p>

      <div class="price-info">
        <h3 class="price" data-aos="fade-right" data-aos-duration="1500">{{ item.price }}$</h3>
        <h4 class="old-price" data-aos="fade-right" data-aos-duration="1500">
          {{ item.oldPrice === "-" ? "" : item.oldPrice + "$" }}</h4>
        <div style="clear: both;"></div>
      </div>
      <a class="button-offer" :href="'/offer/' + item.name.replace(' ', '-')">Rezerwacja</a>
    </div>
  </div>
</template>

<script>
import OfferBadge from "@/components/offert/OfferBadge.vue";

export default {
  name: "OfferItem",
  components: {OfferBadge},
  props: {
    item: {
      name: String,
      shortDescription: String,
      description: String,
      info: String,
      location: String,
      image: String,
      price: Number,
      oldPrice: Number,
    }
  },

}
</script>

