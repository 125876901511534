import App from './App.vue'
import { createApp } from "vue";
import router from "@/routerConfig";
import AOS from '@/aos/aos'

let application = createApp(App);

application.use(router)
application.mount('#app')

AOS.init()