<script setup>
    import MenuItem from "@/components/navigation/menu/MenuItem";
</script>

<template>
    <div class="menu">
        <MenuItem v-for="item in menu" v-bind:key="item.route" :item = "item"/>
    </div>
    <div class="mobile-menu">
        <div @click="mobileOpen = !mobileOpen" class="menu-icon">
            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="mobile-menu-list" :class="mobileOpen ? '' : 'mobile-menu-list-hide'">
            <MenuItem @click="mobileOpen = false" v-for="item in menu" v-bind:key="item.route" :item = "item"/>
        </div>
    </div>
</template>

<script>

    export default {
        name: "MenuComponent",
        data() {
            return {
                menu: [
                    { name: 'Home', route: '/' },
                    { name: 'Punta Cana', route: '/offers/punta-cana' },
                    { name: 'Puerto Plata', route: '/offers/puerto-plata' },
                ],
                mobileOpen: false,
            };
        },
        methods: {
            isOpenMobile() {
                return this.mobileOpen;
            }
        }
    }
</script>

<style scoped lang="sass">
    .menu
        display: flex
    .mobile-menu
        display: none

    .mobile-menu
        .mobile-menu-list-hide
            display: none !important
        .mobile-menu-list
            top: 0
            left: 0
            width: 100vw
            padding: 1vh 10vw
            margin-top: 100px
            position: absolute
            background: rgba(30, 30, 30, 0.16)
            display: flex
            flex-direction: column
            align-items: center
            .not-current, .current
                background: rgba(30, 30, 30, 0.2)
                width: 100%
                text-align: center
            .current
                background: rgba(30, 30, 30, 0.25)
                border: 2px #313131 solid
        .menu-icon
            background: var(--background-primary)
            border-radius: 12px
            width: 2.6rem
            height: 2.6rem
            display: flex
            align-items: center
            justify-content: space-around
            cursor: pointer
            > div > div
                width: 1.6rem
                height: 4px
                border-radius: 12px
                background-color: var(--text-color-primary)
                margin: 5px 0
</style>