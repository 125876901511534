function activeGlobalWaveAnimation() {
    document.addEventListener("click", function(event) {
        applyWaveAnimation(event)
    });
}

function applyWaveAnimation(event) {
    if (!event.target.classList.contains('wave')) {
        return
    }

    let x = event.offsetX
    let y = event.offsetY

    let wave = document.createElement('span')

    wave.classList.add('wave-element')
    wave.style.left = x + 'px'
    wave.style.top = y + 'px'

    event.target.appendChild(wave)

    setTimeout(() => {
        wave.remove()
    }, 1000)
}

export default activeGlobalWaveAnimation