<template>
    <div id="logo">
        <router-link  class="logo-font" to="/">Dominikana Wycieczki</router-link>
    </div>
</template>


<script>
    export default {
        name: "SaveMcLogo"
    }
</script>


<style lang="sass" scoped>
    #logo
        display: flex
        align-items: center

    .logo-font
        color: white
        font-size: 3em
        padding-top: 15px
        display: block
        margin-left: 0.75em
        margin-right: 0.4em
        font-weight: 400

    img
        height: 3.5em
        filter: invert(0.8)

    [data-theme=dark] img
        filter: invert(0.0)
</style>