<script setup>
    import ThemeContainer from "@/components/theme/ThemeContainer";
    import Navigation from "@/components/navigation/Navigation";
    import ContactComponent from "@/components/contact/ContactComponent";

</script>

<template>
    <ThemeContainer>
        <Navigation/>
        <RouterView/>
        <ContactComponent/>
        <footer>
            <p>Wszelkie prawa zastrzeżone &copy; 2021-2022 | <a href="/"> Dominkana-wycieczki-fakultatywne.pl</a></p>
        </footer>
    </ThemeContainer>
</template>

<script>
    import waveAnimation from "@/style/wave/wave";

    waveAnimation()

    export default {
        name: "MainView"
    }

</script>

<style lang="sass">
    @import 'style/font'
    @import 'main'
    @import "style/offer"
    @import 'style/wave/wave'
</style>