<template>
    <a
        :key="item.name"
        :class="[this.isCurrentRoute(item) ? 'current' : 'not-current', 'item main-font wave']"
        @click="this.handleClickItem"
    >
        {{ item.name }}
    </a>
</template>

<script>

    export default {
        name: "MenuItem",
        props: {
            item: undefined
        },

        methods: {
            isCurrentRoute() {
                return this.item.route === this.$route.path
            },

            handleClickItem() {
                this.moveToRoute(this.item.route)
            },

            moveToRoute(route) {
                this.$router.push(route)
            }
        }
    }
</script>

<style lang="sass" scoped>
    .item
        cursor: pointer
        user-select: none
        border-radius: 8px
        margin: 12px
        font-size: 1.1em
        padding: 8px 30px

    .current
        background: var(--background-transparent)
        color: white !important
    .not-current
        background: rgba(0, 0, 0, 0)
        color: white !important
</style>