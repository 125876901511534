<template>
    <main class="shop-page">
        <br>
        <br>
        <br>
        <br>
        <OfferList
            :offers="offersByLocation(this.location)"
        />
    </main>
</template>

<style lang="sass" scoped>
    main
        overflow: auto
</style>
<script>
    import OfferList from "@/components/offert/OfferList";
    import {offersByLocation} from "@/components/offert/offerData";

    export default {
      methods: {
        offersByLocation,
      },
      props: {
        location: String,
      },
        components: { OfferList }
    }
</script>