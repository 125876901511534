import {BOTH, PUERTO_PLATA, PUNTA_CANA} from "@/components/offert/location";

export function offers() {
    return [
        // grande_preciosa_breton_dudu
        // --- Plaże Północy Grande Preciosa Breton z Parkiem Narodowym Cenoty i jaskinie Dudu ---
        {
            name: "Plaże Północy Grande Preciosa Breton z Parkiem Narodowym Cenoty, jaskinie Dudu i Wodospady",
            locationType: PUERTO_PLATA,
            location: "Puerto Plata",
            info: "Od 6 osób",
            price: 75.0,
            oldPrice: 85.0,
            image: "grande_preciosa_breton_dudu/plaza1.jpg",
            shortDescription: `
                Odkryj rajskie plaże, tajemnicze cenoty i dziką przyrodę Dominikany w niezapomnianej wycieczce. 
                Rozpocznij dzień od skoku na zipline w jaskini Dudu, ciesz się widokiem oceanu z klifu Parku Narodowego, 
                a na zakończenie relaksuj się na uroczych plażach i smakuj lokalne specjały. Przyjedź i przeżyj niepowtarzalne przygody! ...
            `,
            description: `
                <p>Zapraszamy na wyjątkową podróż śladami rajskich plaż, cenot oraz jaskiń, a także dziewiczej przyrody.</p>
                <p>Wyruszamy o godzinie 7:30 po porannym śniadaniu w hotelu. Nasza trasa zaczyna się od odwiedzenia Dudu, gdzie znajdują się urocze cenoty i fascynująca jaskinia. Jeśli odważysz się na to, możesz skorzystać z niezwykłego skoku na linie z linii zipline za symboliczną opłatą 1 dolar.</p>
                <p>Później jedziemy samochodem pod wododospad z którego wysportowani dominikańczycy skaczą oraz wchozą po skałach na sam szczyt. Wodospad jest naszym nowym odkryciem. Krystaliczna woda kusi do kąpieli oraz pięknych pamiątkowych zdjęć.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/grande_preciosa_breton_dudu/plaza1.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/grande_preciosa_breton_dudu/plaza2.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/grande_preciosa_breton_dudu/plaza3.jpg" style="width: 30%; border-radius: 10px">
                </div>
                <p>Następnie kierujemy się w stronę Parku Narodowego, który słynie z przepięknej plaży Breton oraz dzikiej przyrody. Podczas spaceru przez dżunglę dotrzemy na szczyt klifu, skąd rozpościera się zapierający dech w piersiach widok na ocean.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/grande_preciosa_breton_dudu/plaza4.jpg" style="width: 45%; border-radius: 10px">
                    <img src="/offers/grande_preciosa_breton_dudu/jaskinia.jpg" style="width: 45%; border-radius: 10px">
                </div>
                <p>Po tych wrażeniach udajemy się na najpiękniejsze plaże północnej Dominikany. Na plaży Grande będziemy mieli okazję skosztować przepysznych dań z rybą i kurczakiem, podawanych w autentycznym stylu dominikańskiego obiadu. Po obiedzie będzie czas na relaks na plaży, a na zakończenie naszej wyprawy, odkryjemy niesamowitą urodę plaży Preciosa.</p>
                <p>Planujemy powrót około godziny 17:00.</p>
            `
        },
        // samana_bacardi_levantado
        // --- Samana z Los Hiatoses Cayo Levantado - Bacardi
        {
            name: "Samana z Los Hiatoses Cayo Levantado - Bacardi",
            locationType: PUNTA_CANA,
            location: "Od Punta Can do Samana",
            info: "Od 18 osób",
            price: 125.0,
            oldPrice: 140.0,
            image: "samana_bacardi_levantado/domek.jpg",
            shortDescription: `
                Twoja przygoda zaczyna się od magicznej Samany. To wyjątkowa wycieczka, która zapewni Ci nie tylko 
                emocjonujące przeżycia, ale także niesamowite widoki i wiele okazji do relaksu. Zaczynamy podróż do Las Canitas. 
                Tam czeka na Ciebie katamaran motorowy, który zabierze Cię na magiczny park Los Haitises. Po około 45 minutach ...
            `,
            description: `
                      <p>Twoja przygoda zaczyna się od magicznej Samany. To wyjątkowa wycieczka, która zapewni Ci nie tylko emocjonujące przeżycia, ale także niesamowite widoki i wiele okazji do relaksu.</p>
                <p>Zaczynamy podróż do Las Canitas. Tam czeka na Ciebie katamaran motorowy, który zabierze Cię na magiczny park Los Haitises. Po około 45 minutach od wypłynięcia na teren parku, odwiedzisz pierwszy punkt - grota szczęki rekina. To fascynujące miejsce, które zapewnia niezapomniane wrażenia.</p>
                <p>Kolejnym punktem Twojej podróży są ptasie góry, gdzie będziesz miał okazję obserwować fregaty, pelikany i piękne ptaki Laury. Możesz zobaczyć ich gniazda i poznać ich fascynujący świat. Następnie popłyniesz pomiędzy górami, aby jeszcze dokładniej podziwiać ich piękno.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/samana_bacardi_levantado/domek.jpg" style="width: 45%; border-radius: 10px">
                    <img src="/offers/samana_bacardi_levantado/boat.jpg" style="width: 45%; border-radius: 10px">
                </div>
                <p>Twoja podróż prowadzi Cię również do jaskini, gdzie zobaczysz pozostałości po tajemniczych Indianach Taino. To miejsce emanuje historią i tajemnicą, której nie można przegapić.</p>
                <p>Nie sposób zapomnieć o przejażdżce przez cudowne lasy mangrowe, które zapewnią Ci niezwykłe wrażenia natury. Następnie płyniesz na wyspę Cayo Levantado, popularnie znaną jako "Bacardi Island". Tam czeka na Ciebie obiadek, a także możliwość relaksu, opalania się i kąpieli na plaży. Spędzisz tam około 2 godzin, zanurzając się w rajskiej atmosferze.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/samana_bacardi_levantado/gora1.jpg" style="width: 45%; border-radius: 10px">
                    <img src="/offers/samana_bacardi_levantado/plaza1.jpg" style="width: 45%; border-radius: 10px">
                </div>
                <p>Kolejnym punktem Twojej podróży są ptasie góry, gdzie będziesz miał okazję obserwować fregaty, pelikany i piękne ptaki Laury. Możesz zobaczyć ich gniazda i poznać ich fascynujący świat. Następnie popłyniesz pomiędzy górami, aby jeszcze dokładniej podziwiać ich piękno.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/samana_bacardi_levantado/plaza2.jpg" style="width: 45%; border-radius: 10px">
                    <img src="/offers/samana_bacardi_levantado/gora2.jpg" style="width: 45%; border-radius: 10px">
                </div>
                <p>Na koniec powrócisz katamaranem do Las Canitas, a stamtąd autobusem do hotelu. Cała podróż to niesamowite doświadczenie pełne przygód, pięknych widoków i wspaniałych wspomnień. Po całym dniu pełnym wrażeń wracasz do hotelu około godziny 19:30-20:00. To podróż, którą z pewnością będziesz wspominać przez wiele lat.</p>
            `
        },
        // 001_montans_redonda_esmeralda
        {
            name: "Góra Czarownic Plaża, Esmeralda, Plantacje",
            locationType: PUNTA_CANA,
            location: "Montans Redonda",
            info: "Od 6 osób",
            price: 95.0,
            oldPrice: 105.0,
            image: "001_montans_redonda_esmeralda/plaza.jpg",
            shortDescription: `
                Twoja przygoda rozpoczyna się od pierwszego punktu, Montania Redonda. Po dotarciu na miejsce, zostaniesz przetransportowany wygodnym tramwajem, który zawiezie Cię na sam szczyt tej urokliwej góry. Tam czekają na Ciebie niezapomniane atrakcje `,
                    description: `
                <p>Twoja przygoda rozpoczyna się od pierwszego punktu, Montania Redonda. Po dotarciu na miejsce, zostaniesz przetransportowany wygodnym tramwajem, który zawiezie Cię na sam szczyt tej urokliwej góry. Tam czekają na Ciebie niezapomniane atrakcje, takie jak skoki na miotle, huśtawki i hamaki, które pozwolą Ci poczuć się niczym czarodziej.</p>
                <p>Z góry Montania Redonda rozciąga się zapierający dech w piersiach widok na półwysep Samana i malownicze laguny El Limon. Z drugiej strony góry, w oddali majaczą góry orientalne, tworząc niepowtarzalny krajobraz.</p>
                <p>Po tych przeżyciach udajemy się na przepyszny dominikański obiad.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/001_montans_redonda_esmeralda/plaza.jpg" style="width: 45%; border-radius: 10px">
                    <video style="width: 45%; border-radius: 10px" autoplay controls>
                        <source src="/offers/001_montans_redonda_esmeralda/film.mp4" type="video/mp4">
                    </video>
                </div>
                <p>Następnie udajemy się na przepiękną dziewiczą i pustą od tłumów turystów plażę Esmeralda, gdzie plażowanie i miły relaks wśród palm sprawią, że poczujemy się jak w raju.</p>
                <p>Po błogim relaksie odwiedzimy plantacje kakao, kawy i owoców dominikańskich, gdzie podczas degustacji poczujemy smaki karaibskich skarbów natury.</p>
                <p>Wspaniała przygoda pozostanie w Waszych sercach na długie lata.</p>
  
            `
        },
        // --- Dominikana od Kuchni - autentyczne Karaiby ---
        {
            name: "Dominikana od Kuchni - autentyczne Karaiby",
            locationType: PUNTA_CANA,
            location: "Od Punta Cana po Higuey",
            info: "Od 8 do 16 osób",
            price: 95.0,
            oldPrice: 105.0,
            image: "od-kuchni/targowisko.jpg",
            shortDescription: `
                Zaczynamy od uderzenia! Odwiedziny wróżkę Voodoo, jest to tzw. białe voodoo, tylko pozytywne rezultaty!
                Dalsza droga poprowadzi nas przez pola trzciny cukrowej z jej degustacją.
                W miejscowości Higlej, która jest miastem pielgrzymek, odwiedzonym również przez naszego papieża, 
                zwiedzimy największą bazylię na Karaibach Nuestra Seniora de Altagracja i typowo głośne i kolorowe targowisko...
            `,
            description: `
                <p>
                    Zaczynamy od uderzenia!
                    Odwiedziny wróżkę Voodoo, jest to tzw. białe voodoo, tylko pozytywne rezultaty!
                    Dalsza droga poprowadzi nas przez pola trzciny cukrowej z jej degustacją.
                </p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/od-kuchni/vodu.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/od-kuchni/dom.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/od-kuchni/degustacja.jpg" style="width: 30%; border-radius: 10px">
                </div>
                <p>
                    W miejscowości Higlej, która jest miastem pielgrzymek, odwiedzonym również przez naszego papieża,
                    zwiedzimy największą bazylię na Karaibach Nuestra Seniora de Altagracja i typowo głośne i kolorowe targowisko.
                </p>
                 <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/od-kuchni/cygaro.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/od-kuchni/trzcina.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/od-kuchni/kakao.jpg" style="width: 30%; border-radius: 10px">
                </div>
                <p>
                    Za miastem na wyżynie czeka na nas rancho, gdzie można zobaczyć, jak rośnie kawa, kakao, banany, ananasy itp.
                    plus degustacja kawy, kakao oraz herbaty kawowej. 
                </p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/od-kuchni/konie.jpg" style="width: 49%; border-radius: 10px">
                    <img src="/offers/od-kuchni/bazylika.jpg" style="width: 49%; border-radius: 10px">
                </div>
                <p>
                    Zrobimy dominikańską ruletkę z kakao i miodu, zjemy typowy wiejski obiad i odwiedzimy małą przydomową wytwórnię cygar,
                    sami tez możemy skręcić cygaro. Odwiedzimy typowy dom dominikański. Na zakończenie wyprawy udamy się na jedną z najpiękniejszych plaż - Makao.
                    Wyjazd o 8 rano powrót około godziny 17
                 </p>
            `
        },
       /* // --- Montaña Redonda (Góra Czarownic) ---
        {
            name: "Montaña Redonda (Góra Czarownic)",
            locationType: PUNTA_CANA,
            location: "Samana",
            info: "Minimum 10 osób",
            price: 135.0,
            oldPrice: 145.0,
            image: "montana-redonda/main.jpg",
            shortDescription: `
                Rozpocznij niezapomnianą przygodę na Montaña Redonda, znanej również jako Góra Czarownic.
                Wjeżdżamy na szczyt tramwajem górskim, a stamtąd rozciągają się niesamowite widoki na półwysep Samana
                oraz lagunę El Limon. Na szczycie czekają skoki na miotle, huśtawki i hamaki, które dostarczą niezapomnianych wrażeń.
                Po zjeździe czekają konie, którymi ruszamy w kierunku wodospadu El Limon, gdzie możesz się zrelaksować w jego wodach...
            `,
            description: `
                <p>
                  Wycieczka na Montaña Redonda to prawdziwa podróż pełna przygód i cudownych widoków.
                  Rozpoczynamy od parkingu, skąd przesiadamy się na malowniczy tramwaj górski. Po wjeździe na szczyt,
                  czekają nas różnego rodzaju atrakcje, takie jak skoki na miotle, huśtawki i hamaki, które zapewnią dużo zabawy i uśmiechu.
                  Nie zapomnij aparatów, widoki na półwysep Samana i lagunę El Limon są zachwycające.
                </p>
                <img src="/offers/montana-redonda/first.jpg" style="width: 100%; border-radius: 10px">
                <p>
                  Następnie schodzimy w dół i przesiadamy się na malutki katamaran motorowy, który zabierze nas w rejs do Santa Barbara.
                  Po przybyciu na miejsce, przewidziane jest zwiedzanie rancho, gdzie spotkamy przyjazne konie. Wyruszamy na nich w stronę wodospadu El Limon,
                  gdzie można się zanurzyć w erupcji wodnej. To relaksujące i odprężające przeżycie.
                </p>
                <p>
                  Po tym niesamowitym doświadczeniu wracamy na koniach do punktu wyjazdu. Następnie udajemy się do urokliwej Villa Clara,
                  gdzie czeka na nas wyśmienita restauracja z widokiem na morze, co czyni ją doskonałym miejscem na obiad
                  i filiżankę aromatycznej kawy. Widok na wyspę Caya Levantado, otwarty ocean i okoliczne góry jest niezapomniany.
                </p>
   
           
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/montana-redonda/4.jpg" style="width: 65%; border-radius: 10px">
<!--                    <img src="/offers/montana-redonda/third.jpg" style="width: 23.5%; border-radius: 10px">-->
                    <video style="width: 27%; border-radius: 20px" controls>
                        <source src="/offers/montana-redonda/video.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <p>
                  Po obiedzie płyniemy na katamaranie przez 10 minut na wyspę Caya Levantado, znaną również jako Bacardi Island.
                  Tam można spróbować słynnej pinacolady, poleżeć na leżaku i cieszyć się relaksem na plaży przez około 1,3 godziny.
                  To doskonały sposób na zakończenie tej wspaniałej przygody.
                </p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <video style="width: 27%; border-radius: 20px" controls>
                        <source src="/offers/montana-redonda/video.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <p>
                  Po pełnym wrażeń dniu wracamy katamaranem do Las Canitas, gdzie przesiadamy się do autobusu, który zawiezie nas z powrotem
                  do hotelu. Planowany czas powrotu to około godziny 19:00. To będzie niezapomniana czwartkowa wycieczka pełna przygód i pięknych widoków!
                </p>
                
            `
        },*/
        { // --- Wzgórze Izabel ---
            name: 'Wzgórze Izabel',
            locationType: PUERTO_PLATA,
            location: 'Puerto Plata',
            info: 'Minimum 6 osoby<br>Planowany wyjazd 8 rano',
            price: 50.0,
            oldPrice: 60.0,
            image: '1.webp',
            shortDescription: `
                Zwiedzanie zaczniemy od Wzgórza  Izabel, na które wiedziemy kolejka linową.
                Jest to urokliwe miejsce z pięknym botanicznym ogrodem oraz pomnikiem Chrystusa.
                Następnie udamy się na zwiedzanie historycznego centrum miasta, będziemy w znanej uliczce
                "Różowej" oraz "Kolorowych parasolek".
                Zwiedzimy <b>fabrykę cygar</b>, <b>rumu</b> (+4$ z degustacją 8 rodzajów rumu),
                <b>czekolady</b> oraz bulwar nadmorski z fortecą i portem morskim...
            `,
            description: `
                <p>
                    <b>Uwaga ważna wskazówka!</b>
                    Puerto Plata powinna być pierwszą wycieczką od jakiej zaczniecie.
                    Warto tam zakupić miejscowy Internet w przystępnej cennie w punkcje dystrybutora miejscowej
                    sieci (ok. 5 USD) oraz wymienić walutę na PESO (przelicznik jest bardzo korzystny)<br>
                    <b>Do obu czynności jest potrzebny paszport.</b><br><br>
                    Przy okazji można zrobić zakupy w dużym miejscowym markecie NAJLEPSZE CENY: pamiątki, rum,
                    kawa lub kosmetyki ze śluzu ślimaka CARACOL (gorąco polecam-rewelacyjne i bardzo tanie).<br>
                    Zwiedzanie zaczniemy od Wzgórza Izabel, na które wiedziemy kolejka linową. Jest to urokliwe
                    miejsce z pięknym botanicznym ogrodem oraz pomnikiem Chrystusa wzorowanym statuą z Rio De Janeiro.<br>
                    Następnie udamy się na zwiedzanie historycznego centrum miasta, będziemy w znanej uliczce
                    "Różowej" oraz "Kolorowych parasolek". Zwiedzimy fabrykę cygar, rumu (+4$ z degustacją 8 rodzajów rumu),
                    czekolady, gdzie możemy dokonać zakupu produktów od producentów tych wyrobów oraz skosztujemy wyrobów,
                    zapalimy cygaro wypijemy rum. Na zakończenie wycieczki udamy się na bulwar nadmorski z fortecą i portem morskim.
                </p>
            `,
        },


        { // --- Samana 5 w 1 ---
            name: 'Samana 5 w 1',
            locationType: PUERTO_PLATA,
            location: 'Samana, Bacardi, El Limón, Playa Grande, Playa Preciosa',
            info: 'Wyjazd o 5 rano z hotelu.<br>Minimum 8 osób',
            price: 105.00,
            oldPrice: 115.00,
            image: '2.jpg',
            shortDescription: `
                Wyjazd na półwysep Samana, uważany za najpiękniejszy region Dominikany.
                Ta wycieczka to idealne połączenie aktywnego wypoczynku z relaksem na plaży.
                Możliwość podziwiania przepięknych widoków pasm górskich Kordyliery, plantacji bananów,
                trzciny cukrowej, pól ryżowych, drzew awokado czy tropikalnej roślinności...
            `,
            description: `
                <p>
                    <b>Jest to Bestseller!</b> W jeden dzień poznajemy różnorodne piękno północnego wybrzeża.
                    <ul>
                        <li>Najpiękniejsze dziewicze plaże Grande i Preciosa</li>
                        <li>Samana - urokliwy port i prowincja półwyspu Samana</li>
                        <li>Urokliwa wyspa Bacaedi- Cayo Levandato</li>
                        <li>Wodospad (opcjonalnie gratis)</li>
                    </ul>
                    W cenie dojazd, przewodnik, łódka, obiad 😋!
                </p>
                <p>
                    Rano wyjazd w kierunku półwyspu Samana, uważany za najpiękniejszy region Dominikany.
                    Podróż będzie trwała ok. 3-4. godziny (w zależności od hotelu).
                    Ta wycieczka to idealne połączenie aktywnego wypoczynku z relaksem na plaży.
                    Możliwość podziwiania przepięknych widoków pasm górskich Kordyliery, plantacji bananów,
                    trzciny cukrowej, pól ryżowych, drzew awokado czy tropikalnej roślinności.
                    W pierwszej kolejności udamy się na jedne z najpiękniejszych plaż świata Precjoze i Grandes.
                    Później pojedziemy do portu Samana do Malecon Santa Barbara, a tam zobaczymy urokliwy most
                    Napoleona położony w zatoce Samana, gdzie uważni turyści dojrzą w wodzie rozgwiazdy.
                    Następnie wejdziemy na pokład szybkiej łodzi lub katamaranu i popłyniemy na Wsypę Cayo Levandaro,
                    zwaną wyspą Bacardi, która swoja nieoficjalną nazwę zawdzięcza słynnemu rumowi,
                    gdyż to właśnie w tym miejscu została nakręcona jedna z jego reklam.
                    Tam będzie czas na plażowanie i podziwianie zatoki Samana.
                    Wycieczkę zakończymy przed zachodem słońca będziemy powoli wracać do hotelu z bagażem przepięknych widoków z tego dnia.
                    (Opcjonalnie w ramach gratisu udamy się do wodospadu Limon położonego w gminie EL Limon,
                    jednego z najpiękniejszych miejsc w tym kraju! Na miejscu możliwość kąpieli u podnóża wodospadu oraz wykonania przepięknych zdjęć).
                    Dojazd do wodospadu konno dodatkowo płatny 10 USA + napiwek dla przewodnika konnego.
                    Wycieczka odbywa się w każdy środę lub dowolny dzień do uzgodnienia z całą grupą turystów.
                </p>
            `,
        },


        { // --- Rajska Wyspa - All inclusive ---
            name: 'Rajska Wyspa - All inclusive',
            locationType: PUERTO_PLATA,
            location: 'Paradise Island - Cayo Paraiso',
            info: 'Minimum 8 osób',
            price: 80.00,
            oldPrice: 90.0,
            image: '3.webp',
            shortDescription: `
                Wędrówka zaczyna się od malowniczej miejscowości Punta Rucia, słynącej z najładniejszych plaż
                w prowincji Monte Cristi. To najbardziej idylliczny zakątek północnej Dominikany.<br>
                Przygodę zaczynamy od rejsu łodzią motorową po oceanie, gdzie zaskoczymy Was płycizną na środku oceanu,
                aby to uwiecznić porobimy zdjęcia i <b>wykąpiemy się w płytkim basenie oceanu</b>, a następnie...
            `,
            description: `
                <p>
                    <b>Poznaj piękną Rajską Wyspę północnej Dominikany!</b><br>
                    Wędrówka zaczyna się od malowniczej miejscowości Punta Rucia, słynącej z najładniejszych plaż w prowincji Monte Cristi.
                    To najbardziej idylliczny zakątek północnej Dominikany.<br>
                    Przygodę zaczynamy od rejsu łodzią motorową po oceanie, gdzie zaskoczymy Was płycizną na środku oceanu,
                    aby to uwiecznić porobimy zdjęcia i wykąpiemy się w płytkim basenie oceanu, a następnie popłyniemy na Rajską Wyspę.
                    Tam czekają na nas pyszna przekąska z owoców.
                    Następnie ruszymy na Snurkowanie jedynej w swoim rodzaju rafy koralowej.
                    Będziemy podziwiać podwodny świat, niezliczona ilość kolorowych rybek, które będą pływać z Nami.
                </p>
                <p>
                    W drodze powrotnej z Rajskiej Wyspy przepłyniemy pomiędzy wąwozem namorzynowych lasów,
                    gdzie dzika i dziewicza przyroda zostanie na długo w Naszej pamięci.
                </p>
                    Po tych wszystkich emocjach zjemy pyszny lokalny obiad woda i kawa w cenie.<br>
                    (Opcja z alkoholem <br>+1 USD</br> <del>+5 USD</del>)
            `,
        },

        { // --- Przejażdrzka Konna po plaży ---
            name: 'Przejażdrzka Konna po plaży',
            locationType: PUERTO_PLATA,
            location: 'Puerto Plata',
            info: 'Minimum 4 osoby',
            price: 55.00,
            oldPrice: 60.0,
            image: '4.jpg',
            shortDescription: `
                <b>UWAGA! Nasza oferta prywatnej stadniny koni cena nie do przebicia!</b>
                Mamy do zaoferowania przejażdżkę konna po plaży i leśnym wąwozem o każdej porze dnia.
                Dla nowicjuszy poprowadzenie przez koniarza, który będzie Was asekurował.
            `,
            description: `
                <p>
                    <b>UWAGA! Nasza oferta prywatnej stadniny koni cena nie do przebicia!</b>
                    Mamy do zaoferowania przejażdżkę konna po plaży i leśnym wąwozem o każdej porze dnia.
                    Dla nowicjuszy poprowadzenie przez koniarza, który będzie Was asekurował.
                    Konie są spokojne co sprawi, że każdy może śmiało zaznać niezapomnianej przygody stępując czy galopując.
                </p>
            `,
        },

        // santo-domingo
        {
            name: 'Europejskie miasto w Nowym Świecie',
            locationType: BOTH,
            location: 'Santo Domingo',
            info: 'Minimum 10 osób',
            price: 85.00,
            oldPrice: 95.0,
            image: 'santo-domingo/town.jpg',
            shortDescription: `
                Santo Domingo to pierwsze europejskie miasto w Nowym Świecie, pokażemy w trzech odsłonach:
                bogatą, biedną i histortyczną. Zobaczymy najstarszą katedrę w Nowym Świecie...
            `,
            description: `
                <p>Program wycieczki Santo Domingo: 
                Przejazd z punta cana do santo domingo około 1. 45 do 2 godzin 
                Pierwszy punktem który odwiedzimy:</p>
                <ul>
                <li>podziemny ogród Jurajski z oczkami wodnymi tzw: Tres Ojos </li>
                <li>Faro de Colon - latarnia Kolumba </li>
                <li>Obelisk Siostr Mirabal , bohaterek narodowych </li>
                <li>Pałac prezydent wzorowany na waszyngtonskim Capitolu </li>
                <li>przejazd przy chińskiej dzielnicy </li>
                <li>zwiedzanie Casa Reales</li>
                <li>spacer ulica Dam , a więc najstarsza ulica w Nowego Świata </li>
                <li>Panteon Narodowy </li>
                <li>zwiedzanie najstarszej Katedry w Nowym Świecie </li>
                <li>fabryka cygara ( wiodącej marki ) </li>
                </ul>
                <img src="/offers/santo-domingo/town.jpg" style="width: 100%; border-radius: 10px" alt="santo-domingo">
                <p>W międzyczasie zaplanowany jest obiad w stylowej restauracji na Starym Mieście i oczywiście czas wolny na deptaku 
                Wyjazd około godziny 7.00 powrót około godziny 19.00</p>
            `,
        },

        { // --- Wodospady Damajagua ---
            name: 'Wodospady Damajagua',
            locationType: PUERTO_PLATA,
            location: 'Puerto Plata',
            info: 'Minimum 6 osób',
            price: 45.00,
            oldPrice: 50.0,
            image: '6.jpg',
            shortDescription: `
                Park wodny stworzony przez  naturę. Jedna z największych atrakcji turystycznych okolic Puerto Plata.
                Wodospady Damajagua są świetne dla osób, lubiących adrenalinę na tle przepięknej przyrody
                ponieważ jest to rezerwat wielu gatunków zwierząt i roślin. Natura stworzyła zapierające dech
                w piersiach wodospady wytrawione z...
            `,
            description: `
            <p>
                Park wodny stworzony przez naturę. Jedna z największych atrakcji turystycznych okolic Puerto Plata.
                Wodospady Damajagua są świetne dla osób, lubiących adrenalinę na tle przepięknej przyrody,
                ponieważ jest to rezerwat wielu gatunków zwierząt i roślin Natura stworzyła zapierające dech w
                piersiach wodospady wytrawione z wapienia w kanionie.  Przygoda zaczyna się od 12 wodospadów,
                gdzie pokonasz przez każdy poziom, skacząc, pływając i ślizgając się po naturalnych zjeżdżalniach
                w krystalicznie czystej wodzie, postępując zgodnie z instrukcjami przewodników. <br>
                UWAGA; osoby które nie zdecydują się ze skoku z wodospadu mogą go pominąć i zjechać rynną.
                Największą atrakcją jest skalny wąwóz, przez który będziemy przechodzić zamoczeni w wodzie.<br>
                Prosimy ze sobą zabrać obuwie wodne i stroje kąpielowe oraz ręczniki<br>
            </p>
            `,
        },

        { // --- Samana 6 w 1 ---
            name: 'Samana 6 w 1',
            locationType: PUERTO_PLATA,
            location: 'Samana-Port, Samana-Haitses, Bacardi, El Limon, Playa Grande, Playa Precjoza',
            info: 'Minimum 8 osób',
            price: 115.00,
            oldPrice: 125.0,
            image: '7.jpg',
            shortDescription: `
                Wyjazd na półwysep Samana, uważany za najpiękniejszy region Dominikany.
                Ta wycieczka to idealne połączenie aktywnego wypoczynku z relaksem na plaży. Możliwość podziwiania
                przepięknych widoków pasm górskich Kordyliery, plantacji bananów, trzciny cukrowej, pól ryżowych,
                drzew awokado czy tropikalnej roślinności...
            `,
            description: `
                <p>
                    Jest to <b>Bestseller</b>, ponieważ poznajemy w jeden  dzień  różnorodne piękno północnego wybrzeża
                    <ul>
                        <li>Samana - urokliwy port i prowincja półwyspu Samana</li>
                        <li>Półwysep Samana z zapierającym w dech w piersiach Narodowym Parkiem  Haitises  z dzika przyroda i dzikim ptactwem</li>
                        <li>najpiękniejsze dziewicze plaże Grande i Preciose</li>
                        <li>cudowny wodospad połączony z przejażdżką konną (z koni można zrezygnować można dojść pieszo)</li>
                        <li>przepiękna wyspa CAYO LEYANTADO zwana Bacardi</li>
                    </ul>

                    Wyjazd 4 rano z hotelu w kierunku półwyspu Samana, uważany za najpiękniejszy region Dominikany.<br>
                    Podróż będzie trwała ok. 3-4 godziny (w zależności od hotelu) Ta wycieczka to idealne połączenie aktywnego wypoczynku z relaksem na plaży. Możliwość podziwiania przepięknych widoków pasm górskich Kordyliery, plantacji bananów, trzciny cukrowej, pól ryżowych, drzew awokado czy tropikalnej roślinności.<br>
                    Wycieczkę zaczynamy od jednych z najpiękniejszych plaż świata Preciosy i Grandes, tam wykąpiemy się, porobimy przepiękne zdjęcia w tak urokliwym miejscu. Następnie udamy się w kierunku wodospadu Limon. Zatrzymamy się na rancho, aby zaznać jazdy konnej (jest to rodzaj koni "turystycznych", gdzie każda osoba ma przewodnika, stąd bezproblemowo nawet nowicjusz, zazna  ekscytującej przygody) i wraz przewodnikami wyruszamy w trasę  podziwiając niesamowitą dziką przyrodę. Ekscytująca przygoda doprowadza nas wodospadu El Limon - jednego z najpiękniejszych miejsc w tym kraju!  Na miejscu możliwość kąpieli u podnóża wodospadu. Następnie wyruszamy do prowincji Samana, gdzie zwiedzimy jeden z najbardziej urokliwych portów, z którego wypłyniemy łódką do Narodowego Parku HAITISES (płynie się w jedną stronę, 40 minut) W drodze powrotnej popłyniemy na wyspę Cayo Levantado, zwaną wyspą Bacardi. Swoją nieoficjalną nazwę zawdzięcza słynnemu rumowi, gdyż to właśnie w tym miejscu została nakręcona jedna z jego reklam. Tam będzie czas na plażowanie i podziwianie zatoki Samana. Późnym wieczorem  będziemy powoli wracać do hoteli z bagażem pięknych widoków z tego dnia.<br>
                    W cenie dojazd, przewodnik, łódka i konie.<br><br>
                    UWAGA OFERTA SUPER HIT! Półwysep Samana zapierającym w dech w piersiach Narodowym Parkiem  Haitises z dzika przyroda + słynna i przepiękna wyspa Bacardi + dwie najpiękniejsze plaże Precjoza i Grande + wodospad Limon to to SUPER HIT nie do przebicia jak na wycieczkę na jeden dzień. To propozycja dla osób ceniących swój czas pieniądze, ponieważ wszystkie te miejsca są wyjątkowe  Warto zaznaczyć ,że są położone w niewielkiej odległości od siebie, a ich dojazd z Puerto Platy to około 3-4 godzin jazdy samochodem w jedną stronę. Proszę zwrócić uwagę, że wiele biur proponuje wyjazdy w te miejsca oferując  w dwóch lub trzech odrębnych wycieczkach.
                </p>
            `,
        },

        { // --- Rajskie Plaze i Cenoty Dudu ---
            name: 'Rajskie Plaze i Cenoty Dudu',
            location: 'Cenot Dudu',
            info: 'Minimum 8 osób',
            price: 72.00,
            oldPrice: 79.0,
            image: 'cenote-dudu/miniature.jpg',
            shortDescription: `
                Zapraszamy do Cenoty i jaskini Taino oraz na malownicze plaże północy Breton z Parkiem Narodowym
                Grande i Precjoza.
                Obiad na plaży Grande.
                Niesamowita wycieczka pełna wrażeń pięknych krajobrazów...
            `,
            description: `
                <p>
                    Zapraszamy do Cenot i jaskini Taino oraz na malownicze plaże północy Breton z Parkiem Narodowym.<br>
                    Grande i Precjoza.<br>
                    Obiad na plaży Grande.<br>
                    Niesamowita wycieczka pełna wrażeń pięknych krajobrazów, dzikiej przyrody i przepięknych karaibskich plaż.<br>
                </p>
            `,
        },

        { // --- SAONA  ---
            name: 'Saona',
            location: 'Specjalna Oferta',
            locationType: PUNTA_CANA,
            info: 'Minimum 8 osób',
            price: "99",
            oldPrice: "110",
            image: 'saona/miniature.jpg',
            shortDescription: `
                Wycieczka na Saonę to niezapomniany dzień pełen atrakcji. 
                Podczas podróży katamaranem do wyspy możesz korzystać z mini baru na pokładzie. 
                Po przybyciu na Saonę czeka cię relaks na plaży z obiadem i barem w cenie. 
                Następnie zwiedzisz urokliwą "piscina natural" na szybkiej łodzi. 
                Na wyspie odkryjesz dzikie plaże i zagospodarowane obszary, a także zapomnisz o szlakach i ścieżkach rowerowych...
            `,
            description: `
                <p><b>Saona oferuje 4 główne atrakcje, tworząc niezapomniany dzień:</b></p>
                <ol>
                    <li><p>Po wjeździe autobusem do pierwszego punktu, Altos de Chavon, malownicze miasteczko otoczone koralowcem zapiera dech w piersi.</p></li>
                    <li><p>Żegluga katamaranem na wyspę Saonę to nie tylko podróż, ale też mini bar na pokładzie w cenie, trwający około 1,5 godziny. Dodatkową atrakcją na pokładzie jest obecność pirata, który dostarcza dodatkowej rozrywki i niezapomnianych chwil.</p></li>
                    <li><p>Na wyspie spędzamy czas z pełnym relaksem, w tym obiadem i barem w cenie, trwający około 2-2,5 godziny.</p></li>
                    <li><p>Następnie szybka łódź zabiera nas do tzw. &quot;piscina natural&quot; - urokliwej płytkiej laguny na otwartym morzu, gdzie również czeka na nas mini bar na pokładzie łodzi w cenie. Spędzamy tam około 45 minut.</p></li>
                </ol>
                
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/saona/pirat.jpg" style="width: 40%; border-radius: 10px; margin: auto">
                </div>
                           
                <p>Po tych fascynujących atrakcjach wracamy szybką łodzią na ląd stały, a następnie wracamy autobusem do hote lu. Park Narodowy to cała wyspa Saona i pewien fragment lądu stałego. Tam nie znajdziemy wyznaczonych szlaków, ścieżek rowerowych czy innych takich udogodnień. Zwiedzamy go, płynąc katamaranem od strony morza i relaksując się na plażach na wyspie.</p>
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <img src="/offers/saona/sun.jpg" style="width: 30%; border-radius: 10px">
                    <img src="/offers/saona/houses.jpg" style="width: 53%; border-radius: 10px">
                </div>
                <p>Kierując się na prawo od plaży, trafiamy na dzikie plaże, gdzie dżungla stopniowo przechodzi w morze, tworząc bajkową scenerię idealną na zdjęcia. Spacer w tamte rejony zajmuje około 10-15 minut. W przeciwnym kierunku znajdują się inne urocze plaże, częściowo zagospodarowane. Odkrywamy na nich niezwykłe piękno, i to zarówno jako odkrywcy, jak i Robinsonowie, w zależności od tego, którą stronę wybierzemy.</p>
                <p>Kiedyś wycieczki prowadziły nas na trzy różne plaże, ale obecnie możemy cieszyć się praktycznie trzema plażami w jednym miejscu, z mnóstwem czasu na ich odkrywanie. Warto wspomnieć, że na pokładzie katamaranu znajduje się także zabawny pirat, który dostarcza dodatkowej rozrywki i urozmaica wycieczkę. Wycieczka całodniowa rozpoczyna się około godziny 7:30 i kończy się około 18:00, oferując pełen dzień przygód i relaksu.</p>

            `,
        },
    ];
}

export function offersByLocation(location) {
    return offers().filter(offer => offer.locationType === location || offer.locationType === BOTH);
}

export function offer(name) {
    return offers().find(offer => offer.name === name);
}