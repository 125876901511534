import { createRouter, createWebHistory } from 'vue-router'
import HomeRoute from "@/components/home/HomeRoute";
import SingleOfferRoute from "@/components/offert/SingleOfferRoute";
import OffersPuertoPlataRoute from "@/components/offert/OffersPuertoPlataRoute.vue";
import OffersPuntaCanaRoute from "@/components/offert/OffersPuntaCanaRoute.vue";

let router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomeRoute },
        { path: "/offers/puerto-plata", component: OffersPuertoPlataRoute },
        { path: "/offers/punta-cana", component: OffersPuntaCanaRoute },
        { path: "/offer/:id", component: SingleOfferRoute },
        { path: '/:catchAll(.*)', component: HomeRoute }
    ]
});

export default router