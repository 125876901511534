<template>
    <div class="theme-container">
        <slot />
    </div>
</template>

<script>
    export default {
        name: "ThemeContainer"
    }
</script>

<style lang="sass">
    @import "theme"
</style>

<style lang="sass">
    .theme-container
        overflow: hidden !important

    body
        background: var(--background-primary)
        color: var(--text-color-primary)
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out
        min-height: 100vh

</style>