<script setup>

import {locationColor, locationDisplay} from "./location";
</script>

<template>
  <div
      data-aos="fade-right"
      class="badge"
      :style=" { 'background-color': locationColor(locationType) }"
  >{{ locationDisplay(locationType) }}</div>
</template>

<script>

export default {
  name: "OfferBadge",
  props: {
    locationType: String,
  },
}
</script>

<style>
.badge {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #2d2257;
  color: #eaeaea;
  margin: 0;
  font-weight: bold;
}
</style>