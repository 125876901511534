<template>
    <section id="offers">
<!--        <h2 style="padding: 25px; width: 80%; margin: 30px auto; color: #5a0b08; border-radius: 13px; text-align: center; background-color: rgba(255,84,86,0.4)">-->
<!--            Uwaga!!! Oferujem od czerwca wycieczki  Punta Cana-->
<!--        </h2>-->
        <h2 data-aos="fade-up" data-aos-duration="2000">Oferty Wycieczek</h2>
        <div class="container">
            <div class="row">
                <OfferItem
                    v-for="item in this.offers"
                    v-bind:key="item.name"
                    :item = "item"
                />
            </div>
        </div>
    </section>

</template>

<script>
    import OfferItem from "@/components/offert/OfferItem";
    export default {
        name: "OfferList",
        components: {OfferItem},
        props: {
            offers: Array
        }
    }
</script>

<style lang="sass">
    .offer-tile
        box-shadow: 0 0 10px var(--accent-color)
        margin: 20px 10px
        display: inline-block
        img
            display: block
            margin: 0
            width: 100%
        h3
            font-weight: 600
        .info-description
            text-align: justify
            font-size: 0.75em

        .price-info
            clear: both
            width: fit-content
            margin: auto
            .price
                float: left
                margin: 0 5px
                display: block
            .old-price
                text-decoration: line-through
                margin: 0
                float: left
                font-size: 0.9em
                display: block
</style>