<template>
    <section id="kontakt">
        <div class="logo">
            <div>
                <h2>Wycieczki Dominikana</h2>
            </div>
        </div>
        <div class="hr"></div>
        <div class="contacts">
            <ContactIn/>
        </div>
        <div style="clear: both;"></div>
    </section>
</template>

<script>
    import ContactIn from "@/components/contact/ContactIn";
    export default {
        name: "ContactComponent",
        components: {ContactIn}
    }
</script>

<style scoped>

</style>