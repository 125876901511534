
export const PUNTA_CANA = "PUNTA_CANA";
export const PUERTO_PLATA = "PUERTO_PLATA";
export const BOTH = "BOTH";

export function locationDisplay(location) {
    switch (location) {
        case PUNTA_CANA:
            return "z Punta Cana";
        case PUERTO_PLATA:
            return "z Puerto Plata";
        case BOTH:
            return "z Punta Cana / Puerto Plata";
        default:
            return "Dominikana";
    }
}

export function locationColor(location) {
    switch (location) {
        case PUNTA_CANA:
            return "#255ed5";
        case PUERTO_PLATA:
            return "#bb244b";
        case BOTH:
            return "#6025d5";
        default:
            return "#12193a";
    }
}